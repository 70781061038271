var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "menu-dropdown black--text",
                          attrs: {
                            text: "",
                            "data-cy": "devicePopoverDropdown",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.isMenuOpened,
            callback: function ($$v) {
              _vm.isMenuOpened = $$v
            },
            expression: "isMenuOpened",
          },
        },
        [
          _vm.isMenuOpened
            ? _c(
                "v-list",
                { attrs: { "data-cy": "devicePopoverMenuOptions" } },
                [
                  _vm.canPerformAction("allowDeviceInfo")
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { link: "", "data-cy": "deviceInfoActivator" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openInfo.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-information")]),
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("map.popover.menu.deviceInfo")) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canPerformAction("allowMenuSettings")
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            link: "",
                            "data-cy": "deviceSettingsActivator",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openSettings.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-settings-box")]),
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("map.popover.menu.deviceSettings")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canPerformAction("allowMenuAlertSetup")
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            link: "",
                            "data-cy": "deviceAlertsActivator",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openAlerts.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-bell")]),
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("map.popover.menu.deviceAlerts")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canPerformAction("allowMenuSharing")
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { link: "", "data-cy": "deviceSharing" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openSharing.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-share-variant")]),
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("map.popover.menu.deviceSharing")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canPerformAction("allowMenuHistory")
                    ? _c(
                        "v-list-item",
                        {
                          attrs: { link: "", "data-cy": "historyActivator" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openHistory.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-chart-line")]),
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("map.popover.menu.deviceHistory")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canPerformAction("allowMenuViewOnMap")
                    ? _c("view-on-map-menu", {
                        attrs: {
                          deviceId: _vm.deviceId,
                          permissions: _vm.permissions,
                          accessLevel: _vm.accessLevel,
                        },
                      })
                    : _vm._e(),
                  _vm.canPerformAction("allowCurrentReadings")
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            link: "",
                            "data-cy": "currentReadingsActivator",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openCurrentReadings.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("v-icon", [
                            _vm._v("mdi-newspaper-variant-outline"),
                          ]),
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "manageDevices.actions.currentReadings"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }