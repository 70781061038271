var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: {
        "widget-fullscreen": _vm.isFullscreen,
        widget: !_vm.isFullscreen,
        "widget-disconnected-device-theme": _vm.enableDisconnectedDeviceTheme,
      },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "widget-header-container vue-draggable-handle" },
        [
          _c(
            "span",
            { staticClass: "widget-actions" },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "menu-dropdown black--text",
                                attrs: {
                                  text: "",
                                  "data-cy": "dashboardWidgetDropdown",
                                },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    [
                      _vm._t("actions"),
                      _c(
                        "v-list-item",
                        {
                          attrs: { link: "", "data-cy": "widgetMenuConfigure" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.dialogs.configure = true
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-settings")]),
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("widget.menu.configure")) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: { link: "", "data-cy": "widgetMenuRemove" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.removeWidget.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-close-circle")]),
                          _c("v-list-item-title", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("widget.menu.remove")) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "widget-title-container" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    bottom: "",
                    "max-width": 600,
                    disabled: !_vm.isNameOverflowing,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "div",
                            _vm._g(
                              {
                                ref: "deviceName",
                                staticClass: "widget-title",
                              },
                              on
                            ),
                            [_c("span", [_vm._v(_vm._s(_vm.title))])]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v(_vm._s(_vm.title))])]
              ),
              _vm.subtitle
                ? _c("div", { staticClass: "widget-subtitle" }, [
                    _c("span", [_vm._v(_vm._s(_vm.subtitle))]),
                    _vm._v("  "),
                    _vm.serial
                      ? _c("span", { staticClass: "device-serial" }, [
                          _vm._v(_vm._s(_vm.serial)),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "widget-left-toolbar" },
            [_vm._t("left-toolbar")],
            2
          ),
          _c("v-spacer"),
          _c(
            "span",
            { staticClass: "widget-right-toolbar" },
            [_vm._t("toolbar")],
            2
          ),
          _vm.fullscreen
            ? _c(
                "span",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "widget-fullscreen-button",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleFullscreen()
                        },
                      },
                    },
                    [
                      _vm.isFullscreen
                        ? _c("v-icon", { attrs: { color: "grey" } }, [
                            _vm._v(" mdi-fullscreen-exit "),
                          ])
                        : _c("v-icon", { attrs: { color: "grey" } }, [
                            _vm._v("mdi-fullscreen"),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("hr", { staticClass: "divider" }),
      _c(
        "v-card-text",
        { staticClass: "widget-content-container no-drag" },
        [_vm._t("content")],
        2
      ),
      _vm.hasFooter ? _c("hr", { staticClass: "divider" }) : _vm._e(),
      _vm.hasFooter
        ? _c("v-card-text", { staticClass: "widget-footer-container" }, [
            _c("div", [_vm._t("left-footer")], 2),
            _c("div", [_vm._t("right-footer")], 2),
          ])
        : _vm._e(),
      _c("configure-dialog", {
        attrs: {
          deviceIds: _vm.deviceIds,
          widgetId: _vm.widgetId,
          widgetType: _vm.widgetType,
          widgetMaxDevices: _vm.widgetMaxDevices,
          open: _vm.dialogs.configure,
          editing: true,
          "data-cy": "addWidgetsDialog",
        },
        on: {
          close: function ($event) {
            _vm.dialogs.configure = false
          },
          widgetUpdated: function ($event) {
            return _vm.updateWidget($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }