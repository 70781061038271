var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        hideOverlay: false,
        persistent: true,
        "data-cy": "currentReadingsDialog",
        "max-width": "500",
      },
      on: { input: _vm.close },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [_vm._t("activator", null, null, on)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "v-card",
        { class: { "disconnected-device-theme": !_vm.isDeviceConnected } },
        [
          _c(
            "v-card-title",
            { staticClass: "widget-header-container" },
            [
              _c(
                "span",
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "black--text",
                                    attrs: {
                                      text: "",
                                      "data-cy": "historyDialogDropdown",
                                    },
                                    nativeOn: {
                                      focus: function ($event) {
                                        return $event.target.blur()
                                      },
                                    },
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("measurement-options", {
                        attrs: {
                          deviceIds: [_vm.currentDeviceId],
                          passedinParameter: _vm.selectedParameter,
                          filterMeasurementStatistics: true,
                        },
                        on: { parameterChange: _vm.parameterChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "widget-title-container" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        "max-width": 600,
                        disabled: !_vm.isNameOverflowing,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  {
                                    ref: "deviceName",
                                    staticClass: "current-readings-title",
                                  },
                                  on
                                ),
                                [_c("span", [_vm._v(_vm._s(_vm.name))])]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.name))])]
                  ),
                  _vm.deviceType
                    ? _c("div", { staticClass: "widget-subtitle" }, [
                        _c("span", [_vm._v(_vm._s(_vm.deviceType))]),
                        _vm._v("  "),
                        _vm.serial
                          ? _c("span", { staticClass: "device-serial" }, [
                              _vm._v(_vm._s(_vm.serial)),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "span",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", "data-cy": "refreshHistory" },
                      on: { click: _vm.refresh },
                    },
                    [
                      _c("v-icon", { attrs: { color: "grey" } }, [
                        _vm._v("mdi-refresh"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c("popover-toolbar", {
                attrs: { deviceId: _vm.currentDeviceId },
              }),
              _c("v-spacer"),
              _c(
                "span",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", "data-cy": "closeHistoryDialog" },
                      on: { click: _vm.close },
                    },
                    [
                      _c("v-icon", { attrs: { color: "grey" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "readings-dialog-content-container" },
            [
              _c("current-readings", {
                attrs: {
                  deviceId: _vm.currentDeviceId,
                  isLoading: _vm.isLoading,
                  selectedParameter: _vm.selectedParameter,
                  latestDeviceStudy: _vm.latestDeviceStudy,
                },
              }),
            ],
            1
          ),
          _c("hr", { staticClass: "divider" }),
          _c("v-card-text", { staticClass: "widget-footer-container" }, [
            _c(
              "div",
              [
                _c("popover-last-calibration-timestamp", {
                  attrs: { deviceId: _vm.currentDeviceId },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("popover-current-calibration-factor", {
                  attrs: { deviceId: _vm.currentDeviceId },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }